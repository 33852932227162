<template>
  <div class="d-flex align-items-center min-vh-100">
    <CModal
            title="Atención"
            color="danger"
            :show.sync="warningModal"
    >
      {{alerta_txt}}
      <template #footer-wrapper>
        <span></span>
      </template>
    </CModal>

    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4">
              <CForm>
                <h1>Recuperar contraseña</h1>




                <CInput
                  placeholder="Correo"
                  autocomplete="email"
                  prepend="@"
                  v-model="correo"
                />

                <CButton @click="recover()" color="success" block>Recuperar contraseña</CButton>
              </CForm>
            </CCardBody>
            <CCardFooter class="p-4">
              <CRow>
                <CCol col="6">
                  <CButton @click="regresar_inicio()" block color="facebook">
                    Regresar al inicio
                  </CButton>
                </CCol>
                <CCol col="6">
                  <CButton @click="registrar_fn()" block color="facebook">
                    Registrar cuenta nueva
                  </CButton>
                </CCol>
              </CRow>
            </CCardFooter>

          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
  export default {
    name: 'Forgot',
    data() {
      return {
        correo : '',
        warningModal: false,
        alerta_txt: '',
      }
    },
    components: {
    },
    methods:{
      recover(){
        this.$http.post('/recover_admision', {
          correo: this.correo,
        }).then(response => {
          this.alerta_txt = 'se ha enviado una contraseña nueva al correo registrado';
          this.warningModal = true;
        })
      },
      regresar_inicio(){
        this.$router.push('/pages/login')
      },
      registrar_fn(){
        this.$router.push('/pages/register')
      },

    }

  }
</script>
